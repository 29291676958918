import { Directus, ID } from '@directus/sdk';
import { RateLimiter } from 'limiter';
import { CodeAction } from 'typescript';
import { Directer } from './directer/directer';

export const use_cms = true; //change to false for local data

export const directus_url = 'https://zi7aprf8.directus.app/';
export const directus_auth_token = 'CN1wp9X4BMUc51rEzTvvdyOH29iW7exN';

type DirectusType = 
| Directus<DirectusProps>
| Directer<DirectusProps>;

export let directus: DirectusType; 

if(use_cms){
	directus = new Directus<DirectusProps>(directus_url, {auth: {staticToken: directus_auth_token}});
} else {
	directus = new Directer<DirectusProps>();
}

export const limiter = new RateLimiter({ tokensPerInterval: 10, interval: 1000});

export type DirectusProps = {
	directus_files: File,
	text_translations: TextTranslation,
	login_code: LoginCode,
	rules: Rule,
	rules_translations: RuleTranslation
	cards: Card,
	cards_translations: CardTranslation
}

export type LoginCode = {
	code: string,
}

export type TextTranslation = {
	id: ID,
	languages_code: string,
	text: string,
	markdown: string,
	text_key: string,

}

export type File = {
	id: ID,
	modified_on: string,
	filename_disk: string,
	filename_download: string,
}

export type Rule = {
	id: ID,
	sort: number,
	translations: ID,
	start_locked: boolean,
}

export type RuleTranslation = {
	id: ID,
	rules_id: ID,
	languages_code: string,
	title: string,
	text: string,
}

export type Card = {
	id: ID,
	translations: ID[],
	image: string,
	card_type: string,
}

export type CardTranslation = {
	id: ID,
	translations: ID,
	languages_code: string,
	cards_id: ID,
	title: string,
	body: string
}