import { useState } from 'react';
import styled from 'styled-components';
import { fadeOut } from '../style/animations';
import Theme from '../style/theme';
import { LoadingIndicator } from './LoadingIndicator';

export const DataLoadingScreen = (props: {dataState: number}) => {

	const [active, setActive] = useState<boolean>(true);

	return(
		<>
			{active && 
				<Container fadeOut={props.dataState === 2} onAnimationEnd={() => setActive(false)}>
					<LoadingIndicator/>
				</Container>
			}
		</>
	);
};

const Container = styled.div<{fadeOut: boolean}>`
	height: 100%;
	width:	100%;
	color: ${p => p.theme.colors.neutralLightest};
	background-color: ${p => p.theme.colors.neutralDarkest};

	display: flex;
	align-items:center;
	justify-content: center;

	animation: ${fadeOut} 1s ease-in forwards;
	animation-delay: ${fadeOut ? '1s': ''}; 
`;