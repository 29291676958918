import { keyframes } from 'styled-components';

export const fadeOut = keyframes`
	0%{
		opacity: 100%;
	}
	99%{
		opacity: 0%;
		pointer-events: all;
	}
	100%{
		opacity: 0%;
		pointer-events: none;
	}
`;

export const fadeIn = keyframes`
	0%{
		opacity: 0%;
	}
	100%{
		opacity: 100%;
	}
`;