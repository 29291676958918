import styled from 'styled-components';

export const Container = styled.div`
	
	// Extra small screens
	@media (max-width: ${p => p.theme.responsive.media.sm}){
		padding: ${p => p.theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${p => p.theme.responsive.media.sm}){
		padding: ${p => p.theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.whitespace.lg}px;

	}
	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
		padding: ${p => p.theme.responsive.whitespace.xl}px;
	}

`;