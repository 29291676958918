import { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { useTextContext } from '../../../context/contexts/text/TextContext';
import Theme from '../../../style/theme';
import { useGameContext } from './Game';


const ignoredSteps : string[] =[
	'draw',
	'discardactivecard',
	'replacetop3',
	'processcard',
	'prestart'
];

export const Announcer = () => {

	const {activeStep, setActiveStep} = useGameContext();
	const {t} = useTextContext();

	const [play, setPlay] = useState<boolean>(false);

	useEffect(() => {
		if(!activeStep || ignoredSteps.includes(activeStep)) return;
		setPlay(true);
	},[activeStep]);

	const handleAnimationEnd = () => {
		setPlay(false);
		if(activeStep === 'nextturn'){
			setActiveStep && setActiveStep('draw');
		}
	};

	return (
		<Container>
			<Announcement play={play} onAnimationEnd={() => handleAnimationEnd()}>
				<h1>{activeStep && !ignoredSteps.includes(activeStep) && t(`game-announcer-${activeStep}`)}</h1>
			</Announcement>
		</Container>
	);
};

// keyframes

const announce = keyframes`
	0%{
        opacity:0;
	};
    20%{
        opacity:1;
    }
    80%{
        opacity:1;
    }
    100%{
        opacity:0;
    }
`;

// styled components

const Container = styled.div`
    position: absolute;
    height: 100%; width: 100%;
    pointer-events: none;
    z-index:2;
`;

const Announcement = styled.div<{play: boolean}>`
    position: absolute;
    background-color: ${Theme.colors.secondary};
	& > h1 {
		color: ${Theme.colors.neutralLightest};
	}

    display:flex; justify-content: center; align-items: center;
    padding-inline: 60px;
	padding-block: 30px;
    top:50%;
	left: 50%;
	transform: translate(-50%,-50%);
    opacity:0;

	clip-path: polygon(100% 0, 100% calc(100% - 1em), calc(100% - 1em) 100%, 0 100%, 0 0);


    animation: ${p => p.play ? announce : ''} 3s forwards;

    & > * {
        margin: 0;
    }
`;