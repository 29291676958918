import { HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { ImageDiv } from '../../../utilities/types';
import { Button } from './Button';

const icons = {
	'close': '600456f3-5674-46a6-8299-6f50b2d7b7ef',
	'information' : '38b06c01-0f88-4167-ae71-a72e74eae20d',
	'arrowleft' : 'b7119074-ba7f-4f25-b187-a8b87949ed34',
	'decrease' : 'd09ef26d-811c-48f9-bed2-61f14c290449',
	'increase' : 'be34b4c6-0277-49c7-b20f-65e2ad62422b',
};

export const RoundButton = (props : RoundButtonProps) => {

	const {getFileURL} = useDataContext();

	const icon = useMemo<string | undefined>(() => {
		if(!getFileURL || !props.icon) return undefined;
		return getFileURL(icons[props.icon]);
	},[props.icon, getFileURL]);

	return (	
		<_RoundButton {...props} disabled={props.disabled}>
			<Icon url={icon} />
		</_RoundButton>
	);
};

const _RoundButton = styled(Button)`
	border-radius: 100%;
	aspect-ratio: 1/1;
	height: 50px;
	display: flex;
	padding: 0;
	margin: 0;
`;

const Icon = styled.div<ImageDiv>`

	margin: auto;

	width: 50%;
	height: 50%;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: bottom;
	background-repeat: no-repeat;

`;

interface RoundButtonProps extends HTMLAttributes<HTMLButtonElement> {
	icon : 'close' | 'information' | 'arrowleft' | 'increase' | 'decrease',
	disabled?: boolean;
}