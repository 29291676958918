import styled from 'styled-components';
import Theme from '../../../style/theme';

export const Button = styled.button`

    border:none;
    border-radius: 0;

    padding-inline: 2em;
    padding-block: .5em;

    cursor: pointer;

	background-color: ${Theme.colors.primary};
	color: ${Theme.colors.neutralLightest};
	box-shadow: 2px 2px 5px .2px rgba(0,0,0,0.2);

	&:hover{
		background-color: ${Theme.colors.primaryDarker};
	}

	&:disabled{
		background-color: ${Theme.colors.primaryLighter};
		pointer-events: none;
	}

	font-size: 1.2em;
	
`;