import cardsRequest from './cards';
import filesRequest from './files';
import loginCodeRequest from './logincode';
import rulesRequest from './rules';


const requests = [
	filesRequest,
	loginCodeRequest,
	rulesRequest,
	cardsRequest,
];

export default requests;