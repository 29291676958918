import { HTMLAttributes, useMemo } from 'react';
import styled from 'styled-components';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import Theme from '../../../style/theme';
import { ImageDiv } from '../../../utilities/types';

export const PlayersList = (props: PlayerListProps) => {

	const {players} = useGlobalContext();

	const {getFileURL} = useDataContext();

	return (
		<Container>
			{ players && Object.keys(players).map((key, index) => 
				<Player 
					key={`playerlist-player-${index}-${key}`}
					playerActive={props.activePlayerId === key}
				>
					<PlayerImage 
						playerActive={props.activePlayerId === key}
					>
						<Image url={props.playerImages[key]} />
					</PlayerImage>
					<PlayerName>{players[key].name}</PlayerName>
				</Player>
			)}
		</Container>
	);
};

// styled components

const Container = styled.div`
	height: 100%; width: 200px;
	margin-right: 50px;
	/* background: hotpink; */

	display: flex;
	flex-direction: column;
/* 
	// Extra small screens
	@media (max-width: ${p => p.theme.responsive.media.sm}){
		padding-block: ${p => p.theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${p => p.theme.responsive.media.sm}){
		padding-block: ${p => p.theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding-block: ${p => p.theme.responsive.whitespace.lg}px;
	}
	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
		padding-block: ${p => p.theme.responsive.whitespace.xl}px;
	} */

`;

const Player = styled.div<{playerActive: boolean}>`
	height: calc(100% / 7);
	padding-bottom: 5%;

	display: flex;
	flex-direction: column;

	color: ${Theme.colors.neutralDarkest};
	
	& > p {
		font-weight: ${p => p.playerActive ? '100' : '50'};
	}

	transition: all .5s;
`;

const PlayerImage = styled.div<{playerActive: boolean}>`
	transition: all .5s;


	flex-grow: 1;
	aspect-ratio: ${p => p.playerActive ? '1.7' : '1.5'}/1;

	border-left: none;
	
	filter: grayscale(${p => p.playerActive ? '0%' : '100%'});
	background-color: ${Theme.colors.secondary};
	margin-right: auto;
`;

const Image = styled.div<ImageDiv>`

	position: absolute;
	inset:5%;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	filter: invert(100%);
`;

const PlayerName = styled.p`
	margin-left: .2rem;
	margin-bottom: 0;
`;

interface  PlayerListProps extends HTMLAttributes<HTMLDivElement>{
	activePlayerId: string | undefined,
	playerImages: (string | undefined)[],
}