import styled from 'styled-components';
import Theme from '../../../style/theme';
import { Container as _Container } from './components/Container';
import { cardAspectRatio } from './Cards/Card';
import { useEffect, useMemo, useState } from 'react';
import { Counter } from './Counter';
import { useGameContext } from './Game';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { ScalableText } from '../../components/ScalableText/ScalableText';


export const Center = () => {

	const {unlockRule} = useGlobalContext();
	const {activeStep, activeRound, rounds, setCardState, proceedToNextRound, setProceedFromVoting} = useGameContext();
	const [votes, setVotes] = useState<number[]>([0,0,0]);
	const [leastVotedSpot, setLeastVotedSpot] = useState<number | undefined>(undefined);
	const [top3, setTop3] = useState<(number | undefined)[]>([undefined, undefined, undefined]);

	useEffect(() => {
		switch(activeStep){
		case 'votefortop3':
			
			setTimeout(() => {unlockRule && unlockRule(7);}, 2000);
			setVotes([0,0,0]);
			setLeastVotedSpot(undefined);
			break;
		case 'replacetop3': 
			// replacement logic for rounds before round 4
			if(!rounds || activeRound === undefined  || !setCardState || !proceedToNextRound) return;
			if(activeRound < 3){
				const newCardId = rounds[activeRound].activeCardId;
				setTop3(v => {v[activeRound] = newCardId; return [...v];});
				setCardState(newCardId, `cardspot-top3-${activeRound}`);
				setTimeout(() => {
					proceedToNextRound();
				}, 3000);
			}
			// replacement logic for replacing cards based on votes
			else{
				if(leastVotedSpot === undefined) return;
				const oldCardId = top3[leastVotedSpot];
				setCardState(oldCardId, 'cardspot-discard');

				const newCardId = rounds[activeRound].activeCardId;
				setCardState(newCardId, `cardspot-top3-${leastVotedSpot}`);
				setTop3(t => {t[leastVotedSpot] = newCardId; return t;});

				setTimeout(() => {
					proceedToNextRound();
				}, 3000);
			}
			break;
		}
	},[activeStep]);

	useEffect(() => {
		// calculate votes and decide wether there is a tie or someone with the least votes.

		let tie = false;
		let lowest;
		let lowestIndex;

		votes.map((value, index) => {
			if(index === 0){
				lowest = value;
				lowestIndex = index;
			}
			else{
				if(lowest > value){ // if the value is lower, update lower var and set tie to false
					lowest = value;
					lowestIndex = index;

					tie = false;
				}
				else if(lowest === value){ // if value is equal, set tie to true
					tie = true;
				}
			}
		});

		if(tie){
			setLeastVotedSpot(undefined);
		}
		else{
			setLeastVotedSpot(lowestIndex);
		}
	},[votes]);

	useEffect(() => {
		if(!setProceedFromVoting) return;
		if(leastVotedSpot !== undefined){
			setProceedFromVoting(true);
		}
		else{
			setProceedFromVoting(false);
		}
	},[leastVotedSpot]);

	return (
		<Container>
			<Labels>
				{[...Array(3)].map((value, index) => 
					<Label key={`cardlabel-${index}`}>{index === 0 ? 'A' : index === 1 ? 'B' : 'C'}</Label>
				)}
			</Labels>
			<Cardspace>
				<CardSpaces> 
					{/* cards */}
					{[...Array(3)].map((value, index) => 
						<CardSpot key={`cardspot-${index}`} id={`cardspot-top3-${index}`}>
							{ activeStep === 'votefortop3' &&
									<>
										<Counter voteSetter={setVotes} index={index}/>
									</>
							}
						</CardSpot>
					)}
				</CardSpaces>
			</Cardspace>
		</Container>

		
	);
};

const Container = styled(_Container)`

	position: relative;
	width: 70%;

	display:flex;
	flex-direction: column;

	background-color: ${Theme.colors.secondaryLightest};
	box-sizing: content-box;
	padding: 2.5%;

`;

const Cardspace = styled.div`
	position: relative;
	height: 80%;
`;

const CardSpaces = styled.div`
	height:100%; width: 100%;
	display: flex;
	justify-content: space-around;
`;

const CardSpot = styled.div`
	aspect-ratio: ${cardAspectRatio};
	border-radius: 20px;
	background-color: ${Theme.colors.neutral};
	position: relative;
`;

const Labels = styled.div`
	display: flex;
	height: 10%;
	justify-content: space-around;
`;

const Label = styled.div`
	font-weight: bold;
	color: ${Theme.colors.secondary};
	font-size: 2em;
`;