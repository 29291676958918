import { ReactNode } from 'react';
import { DataContextProvider } from './contexts/data/DataContext';
import { GlobalContextProvider } from './contexts/global/GlobalContext';
import { TextContextProvider } from './contexts/text/TextContext';


const ContextProvider = (props:{children: ReactNode}) => {
	return (
		<TextContextProvider>
			<DataContextProvider>
				<GlobalContextProvider>
					{props.children}					
				</GlobalContextProvider>
			</DataContextProvider>
		</TextContextProvider>	
	);
};

export default ContextProvider;