import { DefaultTheme } from 'styled-components';

const Theme = {
	colors:{
		neutralLightest: 'white',
		neutralLighter: '#f1f1f1',
		neutral: '#ccd0d0',
		neutralDarker: '#262626',
		neutralDarkest: 'black',
		primaryDarker: '#967700',
		primary: '#F3C000',
		primaryLighter: '#f0d676',
		secondary: '#005e5d',
		secondaryLighter: '#009286',
		secondaryLightest: '#d7dfdf'
	},
	responsive:{
		media : {
			xs: '400px',
			sm: '600px',
			lg: '1025px',
			xl: '1440px',
		},
		whitespace: {
			xs: 20,
			sm: 30,
			lg: 50,
			xl: 75,
		}
	}
};

export default Theme;
