import { HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import Theme from '../../../style/theme';
import { ImageDiv } from '../../../utilities/types';

const maxTime = 2;

export const PlayerPortrait = (props: PlayerPortraitProps) => {

	const {players} = useGlobalContext();

	return(
		<> 
			{players && props.playerId &&
				<Container {...props}>
					<Label>{props.playerLabel}</Label>
					<Portrait active={props.active}>
						<Icon url={props.playerImage}></Icon>
					</Portrait>
					<Name>{players[props.playerId].name}</Name>
				</Container>
			}
		</>
	);
};

const Container = styled.div`
	height: 30vh;
	width: 30vh;

	display: flex;
	flex-direction: column;
	align-items: center;
`;

const Portrait = styled.div<{active: boolean}>`
	position: relative;
	flex-grow: 1;
	width: 100%;
	background-color: ${Theme.colors.secondary};
	margin-block: 10px;

	filter: grayscale(${p => !p.active ? '100%' : '0%'});
`;

const Icon = styled.div<ImageDiv>`

	position: absolute;
	inset:10%;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	filter: invert(100%);

`;

const Name = styled.p`
	color: ${Theme.colors.neutralLightest};
	margin: 0;
	font-weight: 300;
`;

const Label = styled.p`
	color: ${Theme.colors.neutralLightest};
	margin: 0;
`;

const Timer = styled.div`
	position:absolute;
	inset:0;
	display: flex;
	justify-content: center;
	align-items: center;

	background: rgb(0,0,0,0.3);
`;

interface PlayerPortraitProps extends HTMLAttributes<HTMLDivElement>{
	playerImage: undefined | string
	playerId: undefined | string
	playerLabel: string
	active: boolean
}
