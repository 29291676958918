import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../../context/contexts/text/TextContext';
import Theme from '../../../style/theme';
import { Button } from '../../components/buttons/Button';
import { Icon } from '../../components/buttons/CloseButton';
import { IconButton } from '../../components/buttons/IconButton';
import { RoundButton } from '../../components/buttons/RoundButton';
import { cardAspectRatio } from './Cards/Card';
import { Container as _Container } from './components/Container';
import { useGameContext } from './Game';

export const Right = () => {

	const {unlockRule} = useGlobalContext();
	const {cardsStates, setCardsStates, setCardState, activeStep, setActiveStep, proceedFromVoting, proceedToNextRound} = useGameContext();
	const {t} = useTextContext();

	const [instructions, setInstructions] = useState<string>();

	const {getFileURL} = useDataContext();

	const voteIcons = useMemo(() => {
		if(!getFileURL) return;
		return [
			getFileURL('b7119074-ba7f-4f25-b187-a8b87949ed34'),
			getFileURL('600456f3-5674-46a6-8299-6f50b2d7b7ef'),
		];
	}, [getFileURL]);

	useEffect(() => {
		switch(activeStep){
		case 'votefortop3':
			setInstructions(t('game-instruction-votefortop3'));
			break;
		case 'voteforactivecard':
			unlockRule && unlockRule(6);
			setInstructions(t('game-instruction-voteforactivecard'));
			break;
		default:
			setInstructions('');
			break;
		}
	},[activeStep]);

	return (
		<Container>
			<Content>
				<Instruction>{instructions}</Instruction>
				<CardSpace>
					<CardSpot id={'cardspot-deck'}>
					</CardSpot>
				</CardSpace>
				<ButtonRow>
					{ activeStep === 'votefortop3' && 
						<Button 
							id={'votefortop3-button'}
							disabled={!proceedFromVoting}
							onClick={() => setActiveStep && setActiveStep('replacetop3')}
						>{t('game-votefortop3-buttonlabel')}</Button>
					}
					{ activeStep === 'prestart' && 
						<Button 
							onClick={() => proceedToNextRound && proceedToNextRound()}
						>{t('game-start-buttonlabel')}</Button>
					}
				</ButtonRow>
				{ activeStep === 'voteforactivecard' && voteIcons &&
					<>	
						<VoteIn onClick={() => setActiveStep && setActiveStep('votefortop3')} icon={'arrowleft'}>
							<Icon url={voteIcons[0]}/>
						</VoteIn>
						<VoteOut onClick={() => setActiveStep && setActiveStep('discardactivecard')} icon={'close'}>
							<Icon url={voteIcons[1]}/>
						</VoteOut>
					</>
				}
			</Content>
			{/* direction text based on state */}
			{/* decide fate of card buttons */}
			{/* continue button or space for the button */}
		</Container>
	);
};

const Container = styled.div`
	width: 30%;
	margin-left: 5%;
	/* padding-left: 5%; */
`;

const Content = styled(_Container)`

	position: relative;

	height: 100%;
	padding-block: 0;

	display: flex;
	flex-direction: column;
	/* overflow: hidden; */
	/* margin-left: 10px; */
	min-width: 0;
	
`;

const CardSpace = styled.div`
	flex-grow: 1;
	margin-block: 8%;
	display: flex;
	height: 70%;
`;

const CardSpot = styled.div`
	aspect-ratio: ${cardAspectRatio};
	max-height: 100%;
	/* background: yellow; */
	margin-inline: auto;
	position: relative;
`;

const ButtonRow = styled.div`
	display: flex;
	height: 10%;
	flex-direction: column;
	justify-content: end;

	& > button {
		margin-inline: auto;
		/* height: 100%; */
		height: fit-content;
	}
`;

const Instruction = styled.p`
	color: ${Theme.colors.neutralDarkest};
	height: 10%;
	margin: 0;
`;

const Vote = styled(RoundButton)`
	position: absolute;
	height: 4.5em;
	width: 4.5em;
`;


const VoteIn = styled(Vote)`
	left:-5%;top:50%;
	transform: translate(-100%, -50%);
`;

const VoteOut = styled(Vote)`
	left:50%;bottom:0%;
	transform: translate(-50%);
`;