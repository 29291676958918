import { HTMLAttributes, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const AARContainer = (props: AARContainerProps) => {

	const innerContainer = useRef<HTMLDivElement>(null);
	const [fontSize, setFontSize] = useState<number | undefined>(props.fontSize);

	const updateFontSize = () => {
		if(!innerContainer.current || !props.fontSize || !props.originalResolution) return;
		const diff = getResolutionDifference([innerContainer.current.clientWidth, innerContainer.current.clientHeight]);
		if(diff)
			setFontSize(props.fontSize * diff[0]);
	};

	const getResolutionDifference = (currentResolution: [number, number]) => {
		if(!props.originalResolution) return;
		const widthDiff = currentResolution[0] / props.originalResolution[0];
		const heightDiff = currentResolution[1] / props.originalResolution[1];
		return [widthDiff, heightDiff];
	};

	useEffect(() => {
		if(innerContainer.current){
			updateFontSize();
		}
	},[innerContainer.current]);

	useEffect(() => {
		addEventListener('resize', updateFontSize);

		return () => {
			removeEventListener('resize', updateFontSize);
		};
	},[]);


	return (
		<OuterContainer {...props} style={{...props.style,  backgroundColor: props.outerColor, fontSize: fontSize}} fitParent={props.fitParent} > 
			<InnerContainer 
				ref={innerContainer}
				style={{backgroundColor: props.innerColor}} 
				verticalAlignement={props.verticalAlignment} 
				horizontalAlignment={props.horizontalAlignment}
				border={props.border}
				aspectRatio={props.aspectRatio}
			>
				{props.children}
			</InnerContainer>
		</OuterContainer>
	);
};

const OuterContainer = styled.div<{fitParent: boolean | undefined}>`

	height: 100${p => p.fitParent ? '%' : 'vh'};
	width: 100${p => p.fitParent ? '%' : 'vw'};
	position: relative;

`;

const InnerContainer = styled.div<{verticalAlignement?: VerticalAlignmentValue, horizontalAlignment?: HorizontalAlignmentValue, border: boolean | undefined, aspectRatio: string | undefined}>`

	aspect-ratio: ${p => p.aspectRatio ? p.aspectRatio : '16/9'};

	max-height: 100%;
	max-width: 100%;

	border: ${p => p.border ? 'solid 5px red' : ''};

	position: absolute;

	left: ${p => { 
		if(!p.horizontalAlignment) return '50%';
		switch(p.horizontalAlignment){
		case 'left':
			return '0';
		case 'center':
			return '50%';
		case 'right':
			return '100%';
		}
	}};

	top: ${p => { 
		if(!p.verticalAlignement) return '50%';
		switch(p.verticalAlignement){
		case 'top':
			return '0%';
		case 'center':
			return '50%';
		case 'bottom':
			return '100%';
		}
	}}; 

	right: ${p => { 
		if(!p.horizontalAlignment) return '-150%';
		switch(p.horizontalAlignment){
		case 'left':
			return '-200%';
		case 'center':
			return '-150%';
		case 'right':
			return '-200%';
		}
	}};
	
	bottom: ${p => { 
		if(!p.verticalAlignement) return '-150%';
		switch(p.verticalAlignement){
		case 'top':
			return '-100%';
		case 'center':
			return '-150%';
		case 'bottom':
			return '-200%';
		}
	}};

	transform: translate(
		${p => { 
		if(!p.horizontalAlignment) return '-50%';
		switch(p.horizontalAlignment){
		case 'left':
			return '0';
		case 'center':
			return '-50%';
		case 'right':
			return '-100%';
		}}}, 
		${p => { 
		if(!p.verticalAlignement) return '-50%';
		switch(p.verticalAlignement){
		case 'top':
			return '0';
		case 'center':
			return '-50%';
		case 'bottom':
			return '-100%';
		}}}
	);

`;

interface AARContainerProps extends HTMLAttributes<HTMLDivElement> {
	verticalAlignment?: VerticalAlignmentValue
	horizontalAlignment?: HorizontalAlignmentValue
	outerColor?: string
	innerColor?: string
	border?: boolean
	fitParent?: boolean
	aspectRatio?: string

	// font scaling
	originalResolution?: [number, number] // resolution in pixels [width, height]
	fontSize?: number
}

type VerticalAlignmentValue = 'top' | 'center' | 'bottom';
type HorizontalAlignmentValue = 'left' | 'center' | 'right';

export default AARContainer;