import { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDataContext } from '../../../../context/contexts/data/DataContext';
import { shuffle } from '../../../../utilities';
import { useGameContext } from '../Game';
import { Card, cardAspectRatio } from './Card';

export const Cards = (props: HTMLAttributes<HTMLDivElement>) => {

	const {data, getFileURL} = useDataContext();
	const {cardsStates, setCardsStates, setDeck} = useGameContext();
	
	const cardsData = useMemo(()=>{
		if(!data || !data.cards) return;
		return data.cards;
	},[data]);

	const [cardsSet, setCardsSet] = useState<boolean>(false);

	const typeImages = useMemo(() => {
		return {
			'action' : getFileURL && getFileURL('e9b8d979-3f3a-4dc1-b7d4-64e29ac2262a'),
			'opinion' : getFileURL && getFileURL('1c4e7fa5-4bb1-4014-a2e4-24c105fec4a5'),
		};
	},[getFileURL]);

	useEffect(() => {
		
		if(!cardsData || !setCardsStates || cardsSet) return;

		// get sepperate lists of 'action' and 'opinion' cards

		const opinionCards = Object.values(cardsData).filter(card => card.data.card_type === 'opinion'); // 6
		const actionCards = Object.values(cardsData).filter(card => card.data.card_type === 'action'); // 3

		const states = {};
		const deck: number[] = [];

		shuffle(opinionCards);
		shuffle(actionCards);

		// restrict deck to 9 cards
		for(let i = 0; i < 3; i++){
			deck.push(parseInt(`${actionCards[i].data.id}`));
		}

		for(let i = 0; i < 6; i++){
			deck.push(parseInt(`${opinionCards[i].data.id}`));
		}

		deck.map((id, index) => {
			states[id] = {
				spotId: 'cardspot-deck', // starting spot
				faceup: false,
				positionInDeck: index,
				cardId: id
			};
		});

		shuffle(deck);

		setDeck && setDeck(deck);
		
		setCardsStates(states);

		setCardsSet(true);

	},[cardsData]);
	
	return(
		<Container>
			{cardsData && cardsStates && Object.values(cardsData).map((value, index) => 
				<Card data={value} key={`card-${index}`} typeImages={typeImages}/>
			)}
			<DiscardCardSpot id={'cardspot-discard'}/>
			{props.children}
		</Container>
	);
};

const Container = styled.div`
	position: absolute;
	inset:0;
	height: 100%; width: 100%;
	pointer-events: none;
	z-index: 2;
`;

const DiscardCardSpot = styled.div`

	position: absolute;
	aspect-ratio: ${cardAspectRatio};
	height: 80%;

	right: -100%;top:50%;

`;