import { useMemo } from 'react';
import styled from 'styled-components';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { ImageDiv } from '../../../utilities/types';
import { IconButton } from './IconButton';

export const CloseButton = (props) => {

	const {getFileURL} = useDataContext();

	const icon = useMemo(() => 
		getFileURL && getFileURL('8070b100-292d-4375-83ea-dc7a5f9194a2')
	,[getFileURL]);

	return(
		<IconButton {...props}>
			<Icon url={icon}/>
		</IconButton>
	);

};

export const Icon = styled.div<ImageDiv>`
	position: absolute;
	inset:0;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;
`;