import ContextProvider from './context/ContextProvider';
import { DeveloperConsole } from './interface/DeveloperConsole/DeveloperConsole';
import { ForceLandscape } from './interface/ForceLandscape';
import { Rules } from './interface/Rules/Rules';
import { Game } from './interface/Views/game/Game';
import { Introduction } from './interface/Views/Introduction';
import { Login } from './interface/Views/Login';
import { Players } from './interface/Views/Players';
import CustomThemeProvider from './style/CustomThemeProvider';

function App() {
	return (
		<CustomThemeProvider>
			<ContextProvider>
				{/* Generic */}
				<DeveloperConsole/>
				<ForceLandscape/>
				{/* Views */}
				<Login/>
				<Introduction/>
				<Players/>
				<Game/>
				<Rules/>
			</ContextProvider>
		</CustomThemeProvider>
	);
}

export default App;
