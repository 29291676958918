import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../context/contexts/text/TextContext';
import { ImageDiv } from '../../utilities/types';
import { Button } from '../components/buttons/Button';
import { Container } from './components/Container';
import ReactMarkdown from 'react-markdown';

export const Introduction = () => {
	const {gameState, setGameState} = useGlobalContext();
	const {getFileURL} = useDataContext();
	const {t} = useTextContext();

	const image = useMemo(() => {
		return getFileURL && getFileURL('d18449f9-0bed-4746-9c2a-8aaa98efa571');
	},[getFileURL]);

	return (
		<Container gameState={2}>
			<Content>
				<div style={{color: 'black'}}>
					<h1>{t('introduction.title')}</h1>
					<ReactMarkdown>{t('introduction.body', true)}</ReactMarkdown>
					<div style={{marginTop: 'auto'}}><Button onClick={() => setGameState && setGameState(3)}>{t('introduction.continue')}</Button></div>
				</div>
				<Image url={image}/>
			</Content>
		</Container>
	);
};

const Content = styled.div`

	display: flex;

	height: 100%;
	width: 100%;

	gap: 50px;

	& > div {
		width: 50%;
		display: flex;
		flex-direction: column;

		// Extra small screens
		@media (max-width: ${p => p.theme.responsive.media.sm}){
			padding-right: ${p => p.theme.responsive.whitespace.xs}px;
		}

		// Small screens (tablets, big phones, small monitors).
		@media (min-width: ${p => p.theme.responsive.media.sm}){
			padding-right: ${p => p.theme.responsive.whitespace.sm}px;
		}

		// Large screens
		@media (min-width: ${p => p.theme.responsive.media.lg}){
			padding-right: ${p => p.theme.responsive.whitespace.lg}px;

		}
		// Extra large screens
		@media (min-width: ${p => p.theme.responsive.media.xl}){
			padding-right: ${p => p.theme.responsive.whitespace.xl}px;
		}

	}
`;

const Image = styled.div<ImageDiv>`

	position: absolute;
	right:0;
	top:0;
	bottom:0;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: left;
	background-repeat: no-repeat;

	background-color: ${p => p.theme.colors.secondary};

`;