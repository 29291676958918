import { directus } from '../../../../utilities/directus';

const loginCodeRequest = async() => {

	let response;

	await directus.singleton('login_code').read({meta: '*'}).then((data) => {
		if(!data) return;
		response = data.code;
	});

	return {
		response : response,
		collection: 'loginCode'
	};
};

export default loginCodeRequest;