import { HTMLAttributes, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useDataContext } from '../../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../../context/contexts/text/TextContext';
import { fadeIn, fadeOut } from '../../../style/animations';
import Theme from '../../../style/theme';
import { ImageDiv } from '../../../utilities/types';
import { Button } from '../../components/buttons/Button';
import { InformationButton } from '../../components/buttons/InformationButton';
import { cardAspectRatio } from './Cards/Card';
import { Container as _Container} from './components/Container';
import { useGameContext } from './Game';
import { PlayerPortrait } from './PlayerPortrait';

const timerMaxTime = 10;

export const Focus = (props: FocusProps) => {

	const {unlockRule} = useGlobalContext();
	const {activeStep, setActiveStep, setCardState, activeRound, rounds} = useGameContext();
	const {data, getFileURL} = useDataContext();
	const {t} = useTextContext();

	const [active, setActive] = useState<boolean>(false);
	const [activated, setActivated] = useState<boolean>(false);

	//#region activeStep logic

	useEffect(() => {

		if(!activeStep || !rounds || activeRound === undefined || !data) return;

		if(activeStep === 'processcard'){

			// turn on the 'focus' view
			setActive(true);

			// set the cardState of activeCard
			setCardState && setCardState(undefined, 'cardspot-focus');

			// set type based on activeCard
			setType(data.cards[rounds[activeRound].activeCardId].data.card_type);

			// reset phase
			setPhase(0);

			// reset title and body text 
			setTitle(t(`focus.${data.cards[rounds[activeRound].activeCardId].data.card_type}.0.title`));
			setBody(t(`focus.${data.cards[rounds[activeRound].activeCardId].data.card_type}.0.body`));
		}

		else if(activeStep === 'voteforactivecard'){

			// set card state when closing the 'focus' view 
			setActive(false);
			setCardState && setCardState(undefined, 'cardspot-deck');

		}

		else if(activeStep === 'replacetop3'){
			// only close focus view and dont set card state
			setActive(false);
		}

	},[activeStep]);

	//#endregion

	// flow logic

	const [type, setType] = useState<string>();
	const [phase, setPhase] = useState<number>(0);

	// states for the content
	const [title, setTitle] = useState<string>();
	const [body, setBody] = useState<string>();
	const [actionButtonState, setActionButtonState] = useState<boolean>(true); // disable button and show timer

	const nextPhase = () => {

		if(activeRound === undefined || !setActiveStep) return;
		
		// logic for when an opinion card is active
		if(type === 'opinion'){
			switch(phase){
			case 0: // start timer 1
				setActionButtonState(false);
				startTimer();
				break;
			case 2: // start timer 2
				setActionButtonState(false);
				startTimer();
				break;
			case 3: // everyone share
				setTitle(t('focus.opinion.2.title'));
				setBody(t('focus.opinion.2.body'));
				break;
			case 4: // close focus
				closeFocus();
				break;
			}
		}

		// logic for when an action card is active
		if(type === 'action'){
			switch(phase){
			case 0: // close focus
				closeFocus();
				break;
			}
		}

		// increase phase 
		setPhase(p => {return p + 1;});
	};

	// close focus view
	const closeFocus = () => {

		if(activeRound === undefined  || !setActiveStep) return;

		if(activeRound < 3) setActiveStep('replacetop3');
		else setActiveStep('voteforactivecard');

	};

	// unlocking rules

	useEffect(() => {
		if(!unlockRule) return;
		if(type === 'action'){
			setTimeout(() => {unlockRule && unlockRule(5);}, 2000);
		}
		else if(type === 'opinion'){
			setTimeout(() => {unlockRule && unlockRule(1);}, 2000);
		}
	},[type]);

	// prevent first animation

	useEffect(() => {
		console.log({active});
		if(active && !activated){
			console.log('1');
			setActivated(true);
		}
	},[active]);

	// timer
	const maxTime = 2;
	const [timerInterval, setTimerInterval] = useState<any>();
	const [time, setTime] = useState<number>(maxTime);

	const startTimer = () => {
		setTime(maxTime);
		if(timerInterval)
			clearInterval(timerInterval);
		setTimerInterval(setInterval(() => {setTime(a => a - 1);},1000));
	};

	useEffect(() => {
		if(time <= 0){
			clearInterval(timerInterval);
			setTimerInterval(undefined);
			setActionButtonState(true);
		}
	},[time]);

	const clockIcon = useMemo(() => {
		return getFileURL && getFileURL('26348fb5-ae09-4595-9823-5b9d1889b785');
	},[]);

	return (
		<> 
			{ rounds && activeRound !== undefined && rounds[activeRound] &&
			<Container fadeOut={!active} activated={activated}>
				<Content>
					<LeftSpace>
						<div style={{height:'40%'}}>
							<h1 style={{color: Theme.colors.neutralLightest}}>{title}</h1>
							<p>{body}</p>
						</div>
						<InformationButton/>
					</LeftSpace>
					<CardSpace>
						{/* { phase < 3 &&
						<PlayerPortrait 
							playerImage={props.playerImages[rounds[activeRound].activePlayerId]} 
							playerId={rounds[activeRound].activePlayerId}
							playerLabel={'actieve speler'}
							timerActive={phase === 1 && type === 'opinion'}
							onTimerEnd={() => setActionButtonState(true)}
						/>
						} */}
						<CardSpot id={'cardspot-focus'}>
							{ phase < 4 && 
							<PlayerLeft
								active={phase !== 2 && phase !== 3}
								playerImage={props.playerImages[rounds[activeRound].activePlayerId]} 
								playerId={rounds[activeRound].activePlayerId}
								playerLabel={t('game-focus-activeplayer')}
							/>
							}
							{ type === 'opinion' && phase < 4 &&
							<PlayerRight
								active={phase === 2 || phase === 3}
								playerImage={props.playerImages[`${rounds[activeRound].opponentId}`]} 
								playerId={rounds[activeRound].opponentId}
								playerLabel={t('game-focus-opponent')}
							/>
							}
						</CardSpot>
					</CardSpace>
					<RightSpace>
						{/* 
						{ type === 'opinion' && phase < 3 &&
							<PlayerPortrait 
								playerImage={props.playerImages[`${rounds[activeRound].opponentId}`]} 
								playerId={rounds[activeRound].opponentId}
								playerLabel={'tegenstander'}
								timerActive={phase === 2 && type === 'opinion'}
								onTimerEnd={() => setActionButtonState(true)}
							/>
						} */}
						<ActionButton onClick={() => nextPhase()} disabled={!actionButtonState}>
							{ type === 'action' && t('game-focus-buttonlabel-basic')}
							{ type === 'opinion' && t(
								phase === 0 ? 
									'game-focus-buttonlabel-timer1' : phase === 2 ?
										'game-focus-buttonlabel-timer2' : 
										'game-focus-buttonlabel-basic'
							)}
							{ !actionButtonState &&
								<Timer>
									<Icon url={clockIcon}></Icon>
									<p>{time} sec</p>
								</Timer>
							}

							{/* timer during phase 1 && opinion || phase 2 && opinion */}
						</ActionButton>
					</RightSpace>
				</Content>
			</Container>
			}
		</>

	);
};

// styled components

const Container = styled(_Container)<{fadeOut: boolean, activated: boolean}>`
    position: absolute;
    inset:0;

    background-color: rgb(0,0,0,0.8);
	backdrop-filter: blur(20px);
	pointer-events: all;
	opacity: 0;

    animation: ${p => {if(!p.activated) return; return p.fadeOut ? fadeOut : fadeIn;}} 1s forwards;

	z-index: 2;
	color:white;
`;

const Content = styled.div`

    position: relative;

    height: 100%; width: 100%;

    /* background: green; */

    margin-inline: auto;

    display: flex;
`;

const Space = styled.div`
	width: 30%;
    height: 100%;
	padding-bottom: 5%;
	display: flex;
	flex-direction: column;
`;

const LeftSpace = styled(Space)`

`;

const RightSpace = styled(Space)`

`;

const CardSpace = styled.div`
    flex-grow: 1;
    display: flex;
`;

const CardSpot = styled.div`

	position: relative;

    aspect-ratio: ${cardAspectRatio};

    max-height: 100%;
    max-width: 100%;

    margin-inline: auto;
`;

const ActionButton = styled(Button)`

    position: absolute;
    right:0;
    bottom:0;
	
`;

const Timer = styled.div`
	position: absolute;
	inset:0;
	background-color: ${Theme.colors.secondary};
	gap: .25em;
	display: flex;

	justify-content: center;
	align-items: center;

	& > p {
		padding:0;margin:0;
	}

`;

const Icon = styled.div<ImageDiv>`

	height:70%;
	aspect-ratio: 1/1;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: center;
	background-repeat: no-repeat;

	filter: invert(100%);

`;

// players

const PlayerLeft = styled(PlayerPortrait)`
	position: absolute;
	left:0;
	top:50%;
	transform: translate(-130%, -50%);
`;

const PlayerRight = styled(PlayerPortrait)`
	position: absolute;
	right:0;
	top:50%;

	transform: translate(130%, -50%);
`;

// types 

interface  FocusProps extends HTMLAttributes<HTMLDivElement>{
	playerImages: (string | undefined)[],
}