import { ID } from '@directus/sdk';
import { useEffect, useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import styled, { keyframes } from 'styled-components';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { fadeIn, fadeOut } from '../../style/animations';
import Theme from '../../style/theme';
import { Button } from '../components/buttons/Button';
import { CloseButton } from '../components/buttons/CloseButton';
import { RoundButton } from '../components/buttons/RoundButton';

export const Rules = () => {

	const {activeRule, gameState, setActiveRule, unlockedRules, setUnlockedRules, unlockRule} = useGlobalContext();
	const {data} = useDataContext();

	const [text, setText] = useState<string>();

	// unlock rules based on gameState
	useEffect(() => {
		if(!unlockRule) return;
		switch(gameState){
		case 3:
			setTimeout(() => {
				unlockRule(3);
			}, 2000);
			break;
		case 4:
			setTimeout(() => {unlockRule && unlockRule(2);}, 2000);
			break;
		}
	},[gameState]);


	// watch changes of activeRule
	useEffect(() => {
		if(!setUnlockedRules || !unlockedRules || activeRule === undefined || !data) return;
		const newText = data.rules.find(el => el.data.id === activeRule)?.translation.text;
		if(newText)
			setText(newText);
	},[activeRule]);

	useEffect(( ) => {

		if(!data || !data.rules || !setUnlockedRules) return; 

		const newUnlocks : ID[] = [];

		data.rules.map((value, index) => {
			if(!value.data.start_locked && value.data.id){
				newUnlocks.push(value.data.id);
			}
		});
		setUnlockedRules([...newUnlocks]);
	},[data]);

	const [active, setActive] = useState(false);
	useEffect(() => {
		if(activeRule !== undefined){
			setActive(true);
		}
	},[activeRule]);

	return(
		<>
			{ active &&
				<Container fadeOut={activeRule === undefined} >
					<Content slideOut={activeRule === undefined}>
						<Tabs>
							{ data && data.rules && unlockedRules && data.rules.map((rule) =>
								<Tab 
									disabled={unlockedRules.includes(rule.data.id !== undefined ? rule.data.id : '') ? false : true}
									key={`rule-tab-${rule.data.id}`} 
									selected={activeRule === rule.data.id}
									onClick={() => {setActiveRule && setActiveRule(rule.data.id);}}
								>{rule.translation.title}</Tab>
							)}
						</Tabs>
						<TabContent>
							<ScrollingContainer>
								<ReactMarkdown>{text ? text : 'no rule found'}</ReactMarkdown>
							</ScrollingContainer>
						</TabContent>
						<CloseColumn>
							<RoundButton icon={'close'} onClick={() => setActiveRule && setActiveRule(undefined)}/>
						</CloseColumn>
					</Content>
				</Container>
			}
		</>

	);
};

const slideIn = keyframes`
	0%{
		transform: translateY(100vh);
	}
	100%{
		transform: translateY(0);
	}
`;

const slideOut = keyframes`
	0%{
		transform: translateY(0);
	}
	100%{
		transform: translateY(100vh);
	}
`;

const Container = styled.div<{fadeOut: boolean}>`
	position: absolute;
	height:100%; width: 100%;

    background-color: rgb(0,0,0,0.75);
	animation: ${p => p.fadeOut ? fadeOut : fadeIn} 1s forwards;
	animation-delay: ${p => p.fadeOut ? '.15s' : ''};

	backdrop-filter: blur(10px);

	// whitespace

	// Extra small screens
	@media (max-width: ${p => p.theme.responsive.media.sm}){
		padding: ${p => p.theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${p => p.theme.responsive.media.sm}){
		padding: ${p => p.theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.whitespace.lg}px;

	}
	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
		padding: ${p => p.theme.responsive.whitespace.xl}px;
	}

	z-index:4;
`;

const Content = styled.div<{slideOut: boolean}>`
	animation: ${p => p.slideOut ? slideOut : slideIn} forwards ${p => p.slideOut ? '.3s ease-in' : '.5s ease-out'};
	height: 100%; width: 100%;
	/* background-color: pink; */
	display: flex;
	gap: 20px;
`;

const CloseColumn = styled.div`
`;

const StyledCloseButton = styled(CloseButton)`
	position: absolute;
	right:0;top:0;
	margin: 10px;

	height: 30px;
	width: 30px;
`;

const Tabs = styled.div`
	flex-shrink: 0;
	width: 25%; // lacks responsive
	display: flex;
	flex-direction: column;
	gap: 20px;
	overflow-y:auto;

	direction: rtl;
	padding-left: 20px;

	& > * {
		direction: ltr;
	}
`;

const Tab = styled(Button)<{selected: boolean}>`

	/* text-decoration: ${p => p.selected && 'underline'}; */
	color: ${p => p.selected && Theme.colors.neutralDarkest};
	/* border: solid 2px ${Theme.colors.neutralDarker}; */
	/* background-color: ${p => p.selected ? Theme.colors.primary : Theme.colors.neutralLightest}; */
`;

const TabContent = styled.div`
	flex-grow:1;
	padding-inline: 50px;
	padding-block: 1rem;

	background-color: ${Theme.colors.secondaryLightest};
	overflow-y: auto;

	color: ${Theme.colors.neutralDarkest};
`;

const ScrollingContainer = styled.div`
`;