import { directus, Rule, RuleTranslation } from '../../../../utilities/directus';
import { language } from '../../text/TextContext';
import { ManyItems, PartialItem } from '@directus/sdk';

const rulesRequest = async() => {

	const response: {translation: PartialItem<RuleTranslation>, data: PartialItem<Rule>}[] = [];
	const translations: {[key: string] : PartialItem<RuleTranslation>} = {};

	await directus.items('rules_translations').readByQuery({limit: -1, meta: '*', filter:{languages_code: {_eq: language}}}).then((data) => {
		if(!data.data) return;

		data.data.map((translation, index) => {
			if(!translation || !translation.id || !translation.rules_id) return;
			translations[translation.rules_id] = translation;
		});
	});

	await directus.items('rules').readByQuery({limit: -1, meta: '*'}).then((data) => {
		if(!data.data) return;

		data.data.map((rule, index) => {
			if(!rule.sort || !rule.translations || !rule.id) return;
			response[rule.sort -1] = {translation: translations[rule.id], data: rule};
		});

	});

	return {
		response : response,
		collection: 'rules'
	};
};

export default rulesRequest;