import { Component, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { JsxElement } from 'typescript';
import { useGlobalContext } from '../../../context/contexts/global/GlobalContext';
import { fadeIn, fadeOut } from '../../../style/animations';
import Theme from '../../../style/theme';

export const Container = (props: ContainerProps) => {

	const {gameState} = useGlobalContext();
	const [active, setActive] = useState<boolean>(false);

	useEffect(() => {
		if(gameState === props.gameState){
			setActive(true);
		}
	},[gameState]);

	return (
		<>
			{active &&
				<_Container {...props} fadeOut={gameState !== props.gameState} onAnimationEnd={() => {if(gameState !== props.gameState) setActive(false);}}>
					{props.children}
				</_Container>
			}
		</>
	);
};

const _Container = styled.div<{fadeOut: boolean} & JSX.IntrinsicElements['div']>`

    position: absolute;
    inset:0;
    opacity: 0;

    height:100%;
    width:100%;

    /* background-color: hotpink; */

    animation: ${p => p.fadeOut ? fadeOut : fadeIn} .5s forwards;
    animation-delay: ${p => !p.fadeOut ? '.5s' :  '' };

    // whitespace

	// Extra small screens
	@media (max-width: ${p => p.theme.responsive.media.sm}){
		padding: ${p => p.theme.responsive.whitespace.xs}px;
	}

	// Small screens (tablets, big phones, small monitors).
	@media (min-width: ${p => p.theme.responsive.media.sm}){
		padding: ${p => p.theme.responsive.whitespace.sm}px;
	}

	// Large screens
	@media (min-width: ${p => p.theme.responsive.media.lg}){
		padding: ${p => p.theme.responsive.whitespace.lg}px;

	}
	// Extra large screens
	@media (min-width: ${p => p.theme.responsive.media.xl}){
		padding: ${p => p.theme.responsive.whitespace.xl}px;
	}

`;


// types

interface ContainerProps extends React.HTMLAttributes<HTMLDivElement> {
	gameState: number
}