import { Card, CardTranslation, directus, RuleTranslation } from '../../../../utilities/directus';
import { language } from '../../text/TextContext';
import { ManyItems, PartialItem } from '@directus/sdk';

const cardsRequest = async() => {

	const response : {
		[key: number] : CardsDataPackage
	} = {};

	await directus.items('cards').readByQuery({limit: -1, meta: '*'}).then((data) => {
		if(!data.data) return;

		data.data.map((card, index) => {
			if(!card || !card.id) return;
			response[card.id] = {
				data: card,
				translation: null,
			};
		});
	});

	await directus.items('cards_translations').readByQuery({limit: -1, meta: '*', filter:{languages_code: {_eq: language}}}).then((data) => {
		if(!data.data) return;
		data.data.map((translation, index) => {
			if(!translation || !translation.id || !translation.cards_id) return;
			response[translation.cards_id].translation = translation;
		});
	});

	return {
		response : response,
		collection: 'cards'
	};
};

export type CardsDataPackage = {
	translation: PartialItem<CardTranslation>
	data: PartialItem<Card>
}

export default cardsRequest;