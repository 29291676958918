import styled from 'styled-components';
import Theme from '../../../style/theme';

export const IconButton = styled.button`
    border:none;
    border-radius: 0;
	background: none;
	margin: 0;
	padding: 0;

    cursor: pointer;

	display: flex;
	align-items: center;
	justify-content: center;

	&:hover{
		background-color: ${Theme.colors.primaryDarker};
	}

	&:disabled{
		background-color: ${Theme.colors.primaryLighter};
		pointer-events: none;
	}
`;