import { createGlobalStyle } from 'styled-components';
import Theme from './theme';

//eslint-disable-next-line
const fonts = require('../assets/fonts/fonts.css');

const GlobalStyle = createGlobalStyle`

	${fonts};

	html {
		height: -webkit-fill-available;
		min-height: 100%;
		height: 100%;
		font-family: 'Univers', sans-serif;
		font-size: 24px;
		font-weight: 50;
		color: ${Theme.colors.neutral};
	}

	body {
		margin:0;
		padding:0;
		height: 100%;
		overscroll-behavior: contain;
		overflow: hidden;
		background-color: ${Theme.colors.neutralLighter};
	}

	* {
		box-sizing: border-box;
		user-select: none;
	}

	#root {
		height: 100%;
	}

	h1 {
		font-weight: 300;
		margin-top: 0;
		line-height: 80%;
		color: ${Theme.colors.secondary};
	}

	p {
		font-weight: 50;
		margin-top: 0;
	}

	input { 
		border-radius: 0;
		border: solid 2px grey;
		padding: .5rem;
		font-weight: 50;
		font-size: 24px;
		font-family: 'Univers', sans-serif;
	}

	button {
		font-family: 'Univers', sans-serif;
		font-weight: 100;
		font-size: 24px;
	}

	/* custom scrollbar */

	/* width */
	::-webkit-scrollbar {
		width: 7.5px;
	}

	/* Track */
	::-webkit-scrollbar-track {
		background: ${Theme.colors.neutral};
	}
	
	/* Handle */
	::-webkit-scrollbar-thumb {
		background:  ${Theme.colors.neutralLightest}; 
	}

	/* Handle on hover */
	::-webkit-scrollbar-thumb:hover {
		background: #555; 
	}

`;

export default GlobalStyle;
