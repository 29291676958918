import React, { HTMLAttributes, useEffect, useState } from 'react';
import styled from 'styled-components';
import Theme from '../../../style/theme';
import { RoundButton } from '../../components/buttons/RoundButton';

export const Counter = (props: CounterProps) => {

	const [count, setCount] = useState<number>(0);

	useEffect(() => {
		props.voteSetter(v => {v[props.index] = count; return [...v];});
	},[count]);

	return (
		<Container {...props}>
			<RoundButton style={{height: '70%'}} onClick={() => setCount(c => c > 0 ? c - 1 : c)} icon={'decrease'}  disabled={count === 0}>

			</RoundButton>
			<Count>
				<div>{count}</div>
			</Count>
			<RoundButton style={{height: '70%'}} onClick={() => setCount(c => c + 1)} icon={'increase'}>
			</RoundButton>
		</Container>
	);

};

const Container = styled.div`
	position: absolute;
	bottom:0;
	transform: translateY(100%);
	height: 10%; // equal to remaining space
	width: 100%;

	display: flex;
	align-items:center;
	justify-content: space-around;
	padding-top: 5%;
`;

const Count = styled.div`
	height: 100%;
	aspect-ratio: 1/1;

	& > div {
		margin-inline: auto;
		display: flex;
		align-items: center;
		justify-content: center;

		border-radius: 100%;
		background-color: ${Theme.colors.secondary};
		color: ${Theme.colors.neutralLightest};
		font-weight: 100;
		font-size: 1.5em;
		height: 100%;
		aspect-ratio: 1/1;
	}
`;
interface CounterProps extends HTMLAttributes<HTMLElement> {
	voteSetter: React.Dispatch<React.SetStateAction<number[]>>
	index: number;
}