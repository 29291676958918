import { PlayerData, useGlobalContext } from '../../context/contexts/global/GlobalContext';
import { useTextContext } from '../../context/contexts/text/TextContext';
import { Container } from './components/Container';
import ReactMarkdown from 'react-markdown';
import styled, { keyframes } from 'styled-components';
import { Button } from '../components/buttons/Button';
import { useEffect, useMemo, useState } from 'react';
import { useDataContext } from '../../context/contexts/data/DataContext';
import { ImageDiv } from '../../utilities/types';
import { InformationButton } from '../components/buttons/InformationButton';
import Theme from '../../style/theme';

export const Players = () => {

	const {gameState, setGameState, setPlayers, unlockRule} = useGlobalContext();
	const {t} = useTextContext();
	const {getFileURL} = useDataContext();

	const playerImages : (string | undefined)[] = useMemo(() => {

		if(!getFileURL) return [];

		return [
			getFileURL('3ff82eb4-6ecd-4340-acc3-810bdd89f1d6'),
			getFileURL('9795b347-02ab-4eed-9b0e-2461844c40cc'),
			getFileURL('12cc104a-37c7-441f-b7da-6fcdeada9c8b'),
			getFileURL('d911c57a-bbf8-4130-bc6e-566071399438'),
			getFileURL('bb0a9789-79ef-4fe1-b49c-aa0d97fb5a23'),
			getFileURL('8dca1152-437e-48b3-8e44-759fa8dfcd71'),
			getFileURL('d98cb3ed-8e3a-498d-a89f-53ed32764b7e'),
		];

	},[getFileURL]);

	const [_players, _setPlayers] = useState<PlayerData>({});

	const onChangePlayers = (value, index) => {
		const p = {..._players};

		if(value === '' || value === undefined){
			delete p[index];
		}
		else{
			if(p[index]){
				p[index].name = value;
			}
			else{
				p[index] = { name: value };
			}
		}

		_setPlayers(p);
	};

	const onConfirmPlayers = () => {
		setPlayers && setPlayers(_players);
		setGameState && setGameState(4);
	};

	// reset data when going to this view
	useEffect(() => {
		if(gameState === 3)
			_setPlayers({});
	},[gameState]);

	return (
		<Container gameState={3}>
			<Content>
				<h1>{t('players.title')}</h1>
				<ReactMarkdown>{t('players.description', true)}</ReactMarkdown>
				<Flex>
					{ [...Array(7)].map((value, index) => 
						<PlayerEntry key={`playerentry-${index}`} delay={index * 0.4}>
							<div style={{display: 'flex', flexDirection: 'column', flexGrow: '1'}}>
								<Image url={playerImages[index]} highlight={_players[index] ? true : false}>
								</Image>
								<Name>{t(`avatars.${index + 1}.name`)}</Name>
							</div>
							<input placeholder={t('players.input.placeholder')} onChange={(e) => onChangePlayers(e.target.value, index)}/>
						</PlayerEntry>
					)}
				</Flex>
				<div style={{display: 'flex', justifyContent: 'center'}}><Button onClick={() => onConfirmPlayers()} disabled={Object.keys(_players).length < 3}>{t('players.submit')}</Button></div>
				<InformationButton defaultRule={3}/>
			</Content>
		</Container>
	);
};

// keyframes

const slideIn = keyframes`
	0%{
		transform: translateX(-100vw)
	}
	100%{
		transform: translateX(0)
	}
`;

// styled components

const Content = styled.div`
	position: relative;
	display: flex;
	flex-direction: column;
	height: 100%;
	& > p {
		color: ${Theme.colors.neutralDarkest};
	}
`;

const gap = 20;

const Name = styled.p`
	text-align: center;
	margin: 0;
	margin-bottom: 10px;
`;

const Flex = styled.div`

	position: relative;

	flex-grow: 1;
	margin-bottom: 20px;

	display: flex;
	justify-content: center;
	flex-wrap: wrap;

	gap: ${gap}px;
`;

const PlayerEntry = styled.div<{delay: number}>`
	width: calc(25% - ${gap * 3}px / 4);

	display: flex;
	flex-direction: column;

	gap: 5px;

	transform: translateX(-100vw);

	animation: ${slideIn} 1s forwards ease-out;
	animation-delay: ${p => p.delay}s;

	background-color: ${Theme.colors.secondary};

	& > input {
		border: none;
	}
`;

const Image = styled.div<ImageDiv & {highlight: boolean}>`

	position: relative;

	flex-grow: 1;
	flex-shrink: 0;
	
	margin-block: 10px;

	background-image: url(${p => p.url});
	background-size: contain;
	background-position: top;
	background-repeat: no-repeat;

	filter: ${p => p.highlight ? '' : 'grayscale(100%)'} invert(100%);

`;