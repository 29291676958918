import { ID } from '@directus/sdk';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

const GlobalContext = createContext<Partial<GlobalContextProps>>({});

const GlobalContextProvider = (props: {children: ReactNode}) => {

	// general

	// gameState; this value dictates the current state of the game.
	const [gameState, setGameState] = useState<number>(process.env.NODE_ENV === 'production' ? 1 : 1);
	const [overwriteGameState, setOverwriteGameState] = useState<number | undefined>();

	const [activeRule, setActiveRule] = useState<ID | undefined>();
	const [unlockedRules, setUnlockedRules] = useState<ID[]>([]);

	const unlockRule = (ruleId: ID) => {
		if(!unlockedRules.includes(ruleId)){
			setUnlockedRules(ur => [...ur, ruleId]);
			setActiveRule(ruleId);
		}
	};
	
	useEffect(() => {

		const ov = localStorage.getItem('overwriteGameState');
		if(ov === 'undefined' || ov === null || ov === 'null') return;
		setOverwriteGameState(parseInt(ov));
		setGameState(parseInt(ov));

	},[]);

	//players
	const [players, setPlayers] = useState<PlayerData>({
		0: {name: 'player 1'},
		1: {name: 'player 2'},
		2: {name: 'player 3'},
		3: {name: 'player 4'},
		4: {name: 'player 5'},
		5: {name: 'player 6'},
		6: {name: 'player 7'}
	});

	useEffect(() => {
		// 
	},[players]);

	// passing values & functions

	const passedFunctions = {

		setGameState,
		setOverwriteGameState,
		setPlayers,
		setActiveRule,
		setUnlockedRules,
		unlockRule

	};

	const passedValues = {

		gameState,
		overwriteGameState,
		players,
		activeRule,
		unlockedRules

	};		

	//#region render

	return (
		<GlobalContext.Provider value={{...passedValues, ...passedFunctions}}>
			{props.children}
		</GlobalContext.Provider>
	);

	//#endregion
};

// types

type GlobalContextProps = {

	// general
	gameState: number,
	setGameState: (state: number) => void,
	overwriteGameState: number | undefined,
	setOverwriteGameState: (val: number | undefined) => void

	// game
	players: PlayerData,
	setPlayers: (players: PlayerData) => void,

	// rules
	activeRule : ID | undefined,
	setActiveRule : (ruleId: ID | undefined) => void
	unlockedRules : ID[],
	setUnlockedRules : React.Dispatch<React.SetStateAction<ID[]>>,
	unlockRule : (ruleId: ID) => void,

}

export type PlayerData = {
	[key:string]: { 
		name: string,
	}
}


// exports

const useGlobalContext = () => useContext(GlobalContext);

export {GlobalContextProvider, GlobalContext, useGlobalContext};
